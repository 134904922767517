import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { StaticQuery, graphql } from "gatsby"

import Link from '../utils/link'

class RelatedPosts extends Component {

  _renderPost = (el, i) => {
    let post = el
    if (Array.isArray(el.post)) post = el.post[0]
    let url = `/${post.slug}`
    if (post.type !== 'post' && post.type !== 'page') url = `/${post.type}${url}`

    let project = this.props.data.allWordpressWpProject.nodes.find((el, i) => el.slug === post.slug)

    return (
      <Link to={url} key={i}>
        <Fade bottom distance='40px'>
          <picture>
            <img src={project && project.acf.feature_image.localFile.childImageSharp.original.src} alt={post.title} />
          </picture>
          <h4>{ post.title }</h4>
          <h5>{ el.description }</h5>
        </Fade>
      </Link>
    )
  }

  render() {
    return (
      <section className='module__related-posts'>
        <div className='module__related-posts__inner'>
          <Fade bottom distance='40px'>
            <h3>{ this.props.title || 'Related Projects' }</h3>
          </Fade>
          <div className='module__related-posts__list'>
            { this.props.posts && this.props.posts.map(this._renderPost) }
          </div>
        </div>
      </section>
    )
  }
}

export default function RelatedPostsWrap(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
         allWordpressWpProject {
            nodes {
              slug
              acf {
                feature_image {
                  ...original
                }
              }
            }
          }
        }
      `}
      render={data => (<RelatedPosts data={data} {...props} />)}
    />
  )

}
